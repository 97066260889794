import { Button, Flex, Stack } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { useDisclosure, useNetwork } from '@mantine/hooks'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ReportCard } from 'src/entities/report'
import { useBenefitsReport } from 'src/shared/api'
import { BenefitType } from 'src/shared/types/swagger/api.dto'
import { useNotification } from 'src/shared/ui-kit'
import { DateInputWithFloatedLabel } from 'src/shared/ui-kit/date-input-with-floated-label'
import { Modal } from 'src/shared/ui-kit/modal'
import { fileDownload } from 'src/shared/utils'

import { FormSchema } from '../lib/types'

type ReportBenefitProps = {
  title: string
} & Pick<BenefitType, 'id'>

export function ReportBenefit(props: ReportBenefitProps) {
  const [opened, { open, close }] = useDisclosure(false)
  const { t } = useTranslation()
  const { showNotification } = useNotification()
  const { online } = useNetwork()

  const { getInputProps, key, onSubmit, getValues } = useForm<FormSchema>({
    initialValues: {
      reportStartDate: new Date(),
      reportEndDate: new Date(),
    },
    validate: {
      reportStartDate: isNotEmpty(t('field-errors.required')),
      reportEndDate: isNotEmpty(t('field-errors.required')),
    },
  })

  const { reportEndDate, reportStartDate } = getValues()

  const { refetch, data, isSuccess, isLoading } = useBenefitsReport({
    reportEndDate: dayjs(reportEndDate).format('YYYY-MM-DD'),
    reportStartDate: dayjs(reportStartDate).format('YYYY-MM-DD'),
    benefitTypeId: props.id,
  })

  const handleSubmit = () => {
    refetch()
  }

  useEffect(() => {
    if (isSuccess) {
      fileDownload(data)
      close()
      showNotification({ variant: 'success', title: t('reports.success') })
    }
  }, [close, data, isSuccess, showNotification, t])

  return (
    <>
      <ReportCard title={props.title} handleClick={open} />

      <Modal opened={opened} onClose={close} title={props.title}>
        <form onSubmit={onSubmit(handleSubmit)}>
          <Stack gap={8}>
            <Flex gap={8} direction={{ base: 'column', xs: 'row' }}>
              <DateInputWithFloatedLabel
                {...getInputProps('reportStartDate')}
                key={key('reportStartDate')}
                label={t('reports.report-start-date')}
                required
              />

              <DateInputWithFloatedLabel
                {...getInputProps('reportEndDate')}
                key={key('reportEndDate')}
                label={t('reports.report-end-date')}
                required
              />
            </Flex>

            <Flex direction={{ base: 'column', xs: 'row' }} justify="flex-end" gap={8} mt={16}>
              <Button onClick={close} variant="light">
                {t('common.no')}
              </Button>

              <Button type="submit" loading={isLoading} disabled={!online}>
                {t('reports.download-report')}
              </Button>
            </Flex>
          </Stack>
        </form>
      </Modal>
    </>
  )
}
