import { SimpleGrid, Skeleton, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { Breadcrumbs } from 'src/widgets/breadcrumbs'
import { ReportBenefit } from 'src/features/report-benefit'
import { ReportVacationDaysLeft } from 'src/features/report-vacation-days-left'
import { useBenefitTypes } from 'src/shared/api'

const BENEFIT_TYPE_ID_WITHOUT_REPORT = 6

export function HrReportsPage() {
  const { t } = useTranslation()
  const theme = useMantineTheme()

  const { data, isLoading, isSuccess } = useBenefitTypes()

  return (
    <Stack gap={24} component="section">
      <Breadcrumbs />

      <Title order={5} mb={16}>
        {t('common.reports')}
      </Title>

      <Stack gap={24} mb={16}>
        <Text size="xl" ff={theme.headings.fontFamily}>
          {t('common.vacations')}
        </Text>

        <SimpleGrid cols={{ base: 1, xs: 4 }} spacing={24}>
          <ReportVacationDaysLeft />
        </SimpleGrid>
      </Stack>

      <Stack gap={24}>
        <Text size="xl" ff={theme.headings.fontFamily}>
          {t('common.benefits')}
        </Text>

        <SimpleGrid cols={{ base: 1, xs: 4 }} spacing={24}>
          {isLoading ? (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            isSuccess &&
            data
              .filter((el) => el.id !== BENEFIT_TYPE_ID_WITHOUT_REPORT)
              .map((el) => <ReportBenefit key={el.id} title={el.name} id={el.id} />)
          )}
        </SimpleGrid>
      </Stack>
    </Stack>
  )
}
