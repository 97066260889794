import { Button, Flex, Stack } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { useDisclosure, useNetwork } from '@mantine/hooks'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DepartmentMultiSelect } from 'src/entities/department'
import { ReportCard } from 'src/entities/report'
import { useDaysLeftReport } from 'src/shared/api'
import { useNotification } from 'src/shared/ui-kit'
import { DateInputWithFloatedLabel } from 'src/shared/ui-kit/date-input-with-floated-label'
import { Modal } from 'src/shared/ui-kit/modal'
import { fileDownload } from 'src/shared/utils'

import { FormSchema } from '../lib/types'

export function ReportVacationDaysLeft() {
  const { t } = useTranslation()
  const [opened, { open, close }] = useDisclosure(false)
  const { showNotification } = useNotification()
  const { online } = useNetwork()

  const { getInputProps, key, onSubmit, getValues } = useForm<FormSchema>({
    initialValues: {
      reportEndDate: new Date(),
      departmentIds: [],
    },
    validate: {
      reportEndDate: isNotEmpty(t('field-errors.required')),
      // departmentIds: isNotEmpty(t('field-errors.required')),
    },
  })

  const { reportEndDate, departmentIds } = getValues()

  const { refetch, data, isLoading, isSuccess } = useDaysLeftReport({
    reportEndDate: dayjs(reportEndDate).format('YYYY-MM-DD'),
    departmentIds: departmentIds,
  })

  const handleSubmit = () => {
    refetch()
  }

  useEffect(() => {
    if (isSuccess) {
      fileDownload(data)
      close()
      showNotification({ variant: 'success', title: t('reports.success') })
    }
  }, [close, data, isSuccess, showNotification, t])

  return (
    <>
      <ReportCard title={t('reports.vacations-days-left')} handleClick={open} />

      <Modal opened={opened} onClose={close} title={t('reports.vacations-days-left')}>
        <form onSubmit={onSubmit(handleSubmit)}>
          <Stack gap={8}>
            <DateInputWithFloatedLabel
              {...getInputProps('reportEndDate')}
              key={key('reportEndDate')}
              label={t('reports.report-date')}
              required
            />

            <DepartmentMultiSelect {...getInputProps('departmentIds')} key={key('departmentIds')} size="lg" />

            <Flex direction={{ base: 'column', xs: 'row' }} justify="flex-end" gap={8} mt={16}>
              <Button onClick={close} variant="light">
                {t('common.no')}
              </Button>

              <Button type="submit" loading={isLoading} disabled={!online}>
                {t('reports.download-report')}
              </Button>
            </Flex>
          </Stack>
        </form>
      </Modal>
    </>
  )
}
