import { getRandomFileName } from '../get-random-file-name/get-random-file-name'

export function fileDownload(file?: File): void {
  // @ts-expect-error
  const url = window.URL.createObjectURL(file)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = file?.name ?? getRandomFileName()
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
}
