import axios from 'axios'
import qs from 'qs'

import { STORAGE_KEYS } from '../constants'
import { camelizeKeys, cleanQueryParams, decamelizeKeys } from '../utils'

export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URI,
  formSerializer: {
    indexes: null,
  },
  paramsSerializer: (params) => qs.stringify(cleanQueryParams(params), { arrayFormat: 'repeat', skipNulls: true }),
  withCredentials: true,
})

// const getToken = async (role: Role) => {
//   const roleIdMapping = {
//     manager: import.meta.env.VITE_HEAD_ID,
//     employee: import.meta.env.VITE_EMPLOYEE_ID,
//     hr: import.meta.env.VITE_HR_ID,
//     hrd: import.meta.env.VITE_HRD_ID,
//   }

//   const id = roleIdMapping[role]

//   const response = await axios.post(
//     '/get_token',
//     { id },
//     {
//       baseURL: import.meta.env.VITE_BASE_URI,
//     },
//   )

//   return response.data.token
// }

axiosInstance.interceptors.request.use(
  (config) => {
    const newConfig = { ...config }

    if (config.params) {
      newConfig.params = decamelizeKeys(config.params)
    }

    // для 'multipart/form-data' не будет decamelizeKeys
    if (config.data && newConfig.headers['Content-Type'] !== 'multipart/form-data') {
      newConfig.data = decamelizeKeys(config.data)
    }

    const token = localStorage.getItem(STORAGE_KEYS.accessToken)
    if (token) {
      newConfig.headers['Authorization'] = `Bearer ${token}`
    }

    return newConfig
  },
  (error) => {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data && response.headers['content-type'] === 'application/json') {
      response.data = camelizeKeys(response.data)
    }

    return response
  },
  async (error) => {
    // const { config, response } = error

    // if (response && response.status === 401 && import.meta.env.VITE_FEATURE_FLAG_GET_AUTO_TOKEN === 'enabled') {
    //   const role = localStorage.getItem(STORAGE_KEYS.userRole) as Role
    //   try {
    //     const newToken = await getToken(role)
    //     localStorage.setItem(STORAGE_KEYS.accessToken, newToken)

    //     config.headers['Authorization'] = `Besarer ${newToken}`
    //     return axiosInstance(config)
    //   } catch (tokenError) {
    //     return Promise.reject(tokenError)
    //   }
    // }

    return Promise.reject(error)
  },
)
