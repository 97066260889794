import { Card, Group, Text } from '@mantine/core'

import { Icon } from 'src/shared/ui-kit/icon'

import classes from './report-card.module.css'

type ReportCardProps = {
  title: string
  handleClick: () => void
}

export function ReportCard(props: ReportCardProps) {
  return (
    <Card className={classes.reportCard} onClick={props.handleClick}>
      <Group justify="space-between" gap={8} wrap="nowrap">
        <Text className={classes.reportCard__text}>{props.title}</Text>

        <Icon name="arrow-right" size={24} className={classes.reportCard__icon} />
      </Group>
    </Card>
  )
}
