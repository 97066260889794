import { Anchor, Breadcrumbs as BreadcrumbsMantine } from '@mantine/core'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Params, UIMatch, useMatches } from 'react-router-dom'

import { MAIN_APP_URL } from 'src/shared/constants'
import { Icon } from 'src/shared/ui-kit/icon'

type IMatches = UIMatch<
  unknown,
  { crumb: (data: unknown, pathname: string, params: Required<Params<string>>) => React.ReactNode }
>

export const Breadcrumbs = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const matches = useMatches() as IMatches[]

  const crumbs = matches
    .filter((match) => !!match.handle?.crumb)
    .map((match) => {
      if (['/', '/hr', '/manager', '/employee', '/head'].includes(match.pathname)) {
        return (
          <Anchor c="gray.5" fz="sm" td="none" href={MAIN_APP_URL + `/${language}/`}>
            {t('common.home')}
          </Anchor>
        )
      }

      return match.handle.crumb(match.data, match.pathname, match.params)
    })

  return (
    <BreadcrumbsMantine separator={<Icon name="arrow-right" size={16} />} style={{ flexWrap: 'wrap', rowGap: 4 }}>
      {crumbs.map((crumb, i) => (
        <Fragment key={i}>{crumb}</Fragment>
      ))}
    </BreadcrumbsMantine>
  )
}
