import { useMantineTheme } from '@mantine/core'
import { DateInput, DateInputProps } from '@mantine/dates'
import { useMediaQuery } from '@mantine/hooks'
import dayjs from 'dayjs'
import { forwardRef, useState } from 'react'

import { Icon } from '../../icon'

import classes from './date-input-with-floated-label.module.css'

type DateInputWithFloatedLabelProps = Pick<
  DateInputProps,
  'label' | 'value' | 'onChange' | 'required' | 'disabled' | 'minDate' | 'error' | 'maxDate' | 'w'
>

// TODO: добавить маску, либо отказаться от нее
// TODO: добавить поддержку size="md", по аналогии с BaseInputWithFloatedLabel
export const DateInputWithFloatedLabel = forwardRef<HTMLInputElement, DateInputWithFloatedLabelProps>(
  function DateInputWithFloatedLabel({ label, value, onChange, required, disabled, minDate, maxDate, error, w }, ref) {
    const [focused, setFocused] = useState(false)

    const theme = useMantineTheme()
    const matches = useMediaQuery(`(min-width: ${theme.breakpoints.xs})`)

    const floating = focused || !!value || undefined

    return (
      <DateInput
        ref={ref}
        valueFormat="DD.MM.YYYY"
        leftSection={<Icon name="calendar" size={24} fill={theme.colors.light[7]} />}
        leftSectionPointerEvents="all"
        labelProps={{ 'data-floating': floating }}
        classNames={{
          root: classes.root,
          label: classes.label,
          input: classes.input,
          error: classes.error,
          section: classes.section,
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        data-floating={floating}
        label={label}
        value={value && dayjs(value).isValid() ? dayjs(value).toDate() : value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        error={error}
        w={w}
        size={matches ? 'lg' : 'sm'}
      />
    )
  },
)
