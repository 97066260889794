import { Skeleton, useMantineTheme } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { useDepartments, useEmployees } from 'src/shared/api'
import { useUser } from 'src/shared/providers'
import {
  MultiSelectWithFloatedLabel,
  MultiSelectWithFloatedLabelProps,
} from 'src/shared/ui-kit/multi-select-with-floated-label'

type DepartmentMultiSelectProps = Pick<MultiSelectWithFloatedLabelProps, 'value' | 'onChange' | 'size' | 'required'>

export function DepartmentMultiSelect({ onChange, ...rest }: DepartmentMultiSelectProps) {
  const { t } = useTranslation()
  const theme = useMantineTheme()

  const { userData, isLoading: isUserLoading, isSuccess: isUserSuccess } = useUser()
  const {
    data: employeeData,
    isLoading: isEmployeeLoading,
    isSuccess: isEmployeeSuccess,
  } = useEmployees({ employeeId: userData?.id }, isUserSuccess)
  const {
    data: departmentsData = [],
    isLoading: isDepartmentsLoading,
    isSuccess: isDepartmentsSuccess,
  } = useDepartments({
    companyId: employeeData?.[0]?.companyId,
  })

  const departments = departmentsData.map(({ id, name }) => ({
    name,
    id,
  }))

  if (isUserLoading || isEmployeeLoading || isDepartmentsLoading) {
    return <Skeleton w="100%" mih={theme.other.input[rest.size]} />
  }

  if (!isUserSuccess || !isEmployeeSuccess || !isDepartmentsSuccess) return null

  return (
    <MultiSelectWithFloatedLabel
      {...rest}
      onChange={onChange}
      data={departments}
      size="lg"
      label={t('common.departments')}
      withinPortal
    />
  )
}
