import {
  Modal as MantineModal,
  ModalProps as MantineModalProps,
  ModalContentProps,
  Title,
  useMantineTheme,
} from '@mantine/core'
import { ReactElement } from 'react'

import { Icon } from 'src/shared/ui-kit/icon'

import { MODAL_TEST_ID } from '../lib/constants'

export interface ModalProps extends Omit<MantineModalProps, 'centered'> {
  modalContentProps?: ModalContentProps
}

// TODO: удалить - не соответсвует дизайн системе
export function Modal({ modalContentProps, title, children, ...rest }: ModalProps): ReactElement {
  const theme = useMantineTheme()

  return (
    <MantineModal.Root data-testid={MODAL_TEST_ID} {...rest} centered>
      <MantineModal.Overlay />

      <MantineModal.Content radius="lg" p="lg" pt="md" {...modalContentProps}>
        <MantineModal.Header
          display="flex"
          p={0}
          styles={{ header: { alignItems: 'center', justifyContent: 'space-between' } }}
        >
          <Title order={5}>{title}</Title>

          <MantineModal.CloseButton icon={<Icon name="clear" fill={theme.colors.light[5]} />} role="close-button" />
        </MantineModal.Header>

        <MantineModal.Body p={0}>{children}</MantineModal.Body>
      </MantineModal.Content>
    </MantineModal.Root>
  )
}
